
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react'
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next'

import { Layout } from '@components/common'
import { PageView } from '@components/page'

import getLayoutData from '@mw/getLayoutData'
import { fetchPage } from '@mw/query/page'
import { BLOCK_TYPE } from '@components/blocks-cms/Blocks/Blocks'
import { removeParameterFromUrl } from '@utils/url/query'
import { forwardHeaders, updateSessionCountryAndCurrency } from '@mw/session'
import {
  buildQueryParamsAndSlug,
  handlePreview,
  handleRedirection,
} from '@mw/utils'
import { redirectsTo } from '@mw/navigation'
import { logger } from '@utils/logger'
import { HeadError } from '@components/common/Head'
import { transformUrlClientToServer } from '@utils/url/page'
import { getServerState } from 'react-instantsearch'
import { ProductListingAlgolia } from '@components/blocks-cms'
import { renderToString } from 'react-dom/server'

const containsInvalidProductListing = (page: Page): Boolean => {
  const productsListing = page?.blocks?.find(
    (block) => block.blockType === BLOCK_TYPE.LIST_PRODUCTS
  )
  return !!(
    productsListing &&
    'parameters' in productsListing.data &&
    'total' in productsListing.data &&
    productsListing.data.parameters.offset > productsListing.data.total
  )
}

 const _getServerSideProps: GetServerSideProps = async (context) => {
  const { query, locale, req, res } = context
  const { pages, _page, ...params } = query
  const currentPage = parseInt(_page as string)
  const isClientCall = context.req.url?.startsWith('/_next/data')

  // Formward Headers
  const headers = forwardHeaders(req, context)

  const { queryString, path, slug } = buildQueryParamsAndSlug(
    locale,
    headers,
    pages,
    params,
    currentPage
  )

  // Handles Preview Strapi
  let page = await handlePreview(path, locale)

  if (
    !isClientCall &&
    (context.locale === 'catchAll' || req.headers['has-locale'] === 'false')
  ) {
    return handleRedirection(path, page, context, queryString, headers, query)
  }

  const session = await updateSessionCountryAndCurrency(
    query,
    locale,
    queryString
  )

  let layout = null,
    error = null

  try {
    const { cookies, ...restLayout } = await getLayoutData({
      locale,
      headers,
      query: queryString,
      sessionData: session,
    })
    res.setHeader('Set-Cookie', cookies)
    layout = restLayout
  } catch (err) {
    logger.error({
      message: `[fetchLayout]: ${err.message}`,
      additionalData: {
        status: err.status,
        path,
        slug,
        query,
        queryString,
      },
    })
  }

  if (!page) {
    try {
      page = await fetchPage({
        locale,
        path,
        query: queryString,
        headers,
        countryCode:
          query?.country?.toString() || layout?.session.deliveryCountryCode,
      })
    } catch (err) {
      logger.error({
        message: `[...pages]: ${err.message}`,
        additionalData: {
          status: err.status || 500,
          path,
          slug,
          query,
          queryString,
        },
      })
      error = {
        status: err.status || 500,
        message: err.message || `An error occured fetching the page ${slug}`,
      }
      res.statusCode = error.status
    }
  }

  // Redirects if the page exceeds valid product range (e.g., page 8 when only 7 pages exist).
  if (containsInvalidProductListing(page))
    return redirectsTo({
      destination: removeParameterFromUrl(req.url, '_page'),
      permanent: false,
    })

  const redirectUrl = page?.customData?.redirectIfNotLogged?.find(
    (e) => e.hrefLang.toLowerCase() === locale.toLowerCase()
  )?.href
  if (!layout?.session?.logged && redirectUrl)
    return redirectsTo({ destination: redirectUrl, permanent: false })

  if (page?.redirectTo)
    return redirectsTo({ destination: page.redirectTo, permanent: true })

  // check if page has algolia listing
  const algoliaListing = page?.blocks?.find(
    (block) => block.blockType === BLOCK_TYPE.ALGOLIA_LIST_PRODUCTS
  )

  const algoliaServerInfos: AlgoliaServerInfos = {
    serverUrl: null,
    serverState: null,
  }

  if (algoliaListing) {
    algoliaServerInfos.serverUrl =
      process.env.NEXT_PUBLIC_SMALLABLE_BASE_URL +
      transformUrlClientToServer(req.url, locale)
    algoliaServerInfos.serverState = await getServerState(
      <ProductListingAlgolia
        algoliaServerInfos={{ serverUrl: algoliaServerInfos.serverUrl }}
        algoliaListingProps={algoliaListing.data as AlgoliaProductsListingTypes}
      />,
      { renderToString }
    )
  }

  return {
    props: {
      ...layout,
      error,
      locale,
      page: page || null,
      currentPage: currentPage || null,
      alternates: page?.alternatesUrl || [],
      algoliaServerInfos,
    },
  }
}

export default function Pages({
  error,
  page,
  currentPage,
  algoliaServerInfos,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  if (error || !page) return <HeadError />

  return (
    <PageView
      key={page.path}
      page={page}
      currentPage={currentPage}
      algoliaServerInfos={algoliaServerInfos}
    />
  )
}

Pages.Layout = Layout


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[[...pages]]',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  